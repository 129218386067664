import * as React from "react";
import FreshdeskContactForm from "../components/FreshdeskContactForm";
import WebsiteLayout from "../layouts/WebsiteLayout";

const ContactUs = (props) => {

  return (
    <main>
        <WebsiteLayout location={props.location} title="Contact Us">
            <section className="content-header content-header-1 -blue">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="heading">Contact Us</h1>
                        </div>
                    </div>
                </div>
            </section>

            <div className="form form-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        <FreshdeskContactForm 
                        url={"https://djmanagerapp.freshdesk.com"} 
                        formHeight="600px"
                        buttonText={"Submit"}
                        submitThanks={"Thanks for your message, we will get back to you shortly."}
                        />            
                        </div>
                    </div>
                </div>
            </div>

            
        </WebsiteLayout>
    </main>
  )
}

export default ContactUs;